class Services {
    notification = (type, title, message, actions, top, redirect, timeout) => {
        const notificationMain = document.querySelector('.notification-main');

        if (notificationMain) {
            const nIcon = notificationMain.querySelector('.notif-icon-container');
            const nSavedIcon = nIcon.querySelector('.savedIcon');
            const errIcon = nIcon.querySelector('.errorIcon');
            const nTitle = notificationMain.querySelector('.title');
            const nMsg = notificationMain.querySelector('.message');
            const actionsContainer = notificationMain.querySelector('.notif-action-btns');


            nTitle.innerHTML = title;
            nMsg.innerHTML = message;
            actionsContainer.innerHTML = '';

            if (type === 'error') {
                nSavedIcon.classList.add('dn');
                errIcon.classList.remove('dn');
                errIcon.classList.add('show');
                nSavedIcon.classList.remove('show');
            } else {
                errIcon.classList.add('dn');
                nSavedIcon.classList.remove('dn');
                nSavedIcon.classList.add('show');
                errIcon.classList.remove('show');
            }

            if (actions) {
                actionsContainer.classList.remove('dn');
                for (let i = 0; i < actions.length; i++) {
                    const actionBtn = document.createElement('button');

                    if (actions[i] === 'cancel' || actions[i] === 'done' || actions[i] === 'ok') {
                        actionBtn.onclick = () => {
                            notificationMain.classList.remove('active');
                        };
                    } else if(actions[i] === 'reload'){
                        if(redirect){
                            actionBtn.onclick = () => window.location.href = redirect;
                        }else window.location.reload();
                    }else {
                        console.log(redirect);
                    }

                    actionBtn.innerHTML = actions[i];
                    actionsContainer.appendChild(actionBtn);
                }
            } else actionsContainer.classList.add('dn');

            if (top) {
                notificationMain.style.top = top + 'px';
                setTimeout(() => {
                    notificationMain.classList.add('active');
                }, 50);
            } else notificationMain.classList.add('active');
            setTimeout(() => {
                notificationMain.classList.remove('active');
            }, timeout ? timeout : 5000);
        }
    }

    bytesToMB = (bytes) => (bytes / (1024 * 1024)).toFixed(2);

    copyToClipBoard = (text) => {
        try {
            const copier = navigator.clipboard.writeText(text);

            copier.then(() => {})
        } catch (error) {

        }
    }
}

const services = new Services();

export default services;