export const showLoader = (show, full) => {
    let loaderOuter = document.querySelector('.loader-outer');
    if (!loaderOuter) return;

    if (show) {
        if(!full) loaderOuter.classList.add('transparent');
        loaderOuter.classList.remove('hide');
    } else {
        loaderOuter.classList.remove('transparent');
        loaderOuter.classList.add('hide');
    }
};