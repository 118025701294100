import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
    const savedTheme = localStorage.getItem('theme') || 'light';
    const [theme, setTheme] = useState(savedTheme);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
    };

    useEffect(() => {
        document.documentElement.setAttribute('theme', theme);
        if(theme === 'dark'){
            document.body.classList.add('dark-theme');
        }else document.body.classList.remove('dark-theme');
    }, [theme]);

    useEffect(() => {
        const x = localStorage.getItem('theme') || 'light';
        const themeContentElm = document.querySelector('meta[name="theme-color"]');
        themeContentElm.setAttribute('content', x === 'dark' ? themeContentElm.getAttribute('next') : themeContentElm.getAttribute('default'));
    }, []);

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;