import { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AddProductAdmin from './components/admin/AddProduct';
// import { WebSocketProvider } from './services/WebSocketProvider';
import { showLoader } from './utils/loaderUtils';
import './styles/default.css';

const SavedIcon = lazy(() => import('./assets/icons/saved.svg').then(module => ({ default: module.ReactComponent })));
const ErrorIcon = lazy(() => import('./assets/icons/error.svg').then(module => ({ default: module.ReactComponent })));


const Home = lazy(() => import('./pages/Home'));
const Cart = lazy(() => import('./pages/Cart'));
const Profile = lazy(() => import('./pages/Profile'));
const User = lazy(() => import('./pages/small/UserLogin'));

const Admin = lazy(() => import('./pages/Admin'));
const Tools = lazy(() => import('./pages/Tools'));
const Product = lazy(() => import('./pages/Product'));
const Services = lazy(() => import('./pages/Services'));
const Search = lazy(() => import('./pages/small/Search'));
const BuyAndSell = lazy(() => import('./pages/BuyAndSell'));
const AdminLogin = lazy(() => import('./components/admin/AdminLogin'));

// seller
const Seller = lazy(() => import('./pages/Seller'));
const SellerPreview = lazy(() => import('./components/seller/preview/SellerPreview'));
const Dashboard = lazy(() => import('./components/seller/dashboard/Dashboard'));
const Overview = lazy(() => import('./components/seller/overview/Overview'));
const Products = lazy(() => import('./components/seller/products/Products'));
const Orders = lazy(() => import('./components/seller/orders/Orders'));
const Reviews = lazy(() => import('./components/seller/reviews/Reviews'));

// small
const Header = lazy(() => import('./components/Header'));
const Feedback = lazy(() => import('./pages/small/Feedback'));
const ChatWithUs = lazy(() => import('./pages/small/ChatWithUs'));
const ChatComponent = lazy(() => import('./pages/small/ChatComponent'));
const CustomOrder = lazy(() => import('./pages/small/CustomOrder'));
const VerifyEmail = lazy(() => import('./components/redirects/VerifyEmail'));
const LearnMore = lazy(() => import('./pages/small/LearnMore'));
const NotFound = lazy(() => import('./components/404/NotFound'));
const CookiePopup = lazy(() => import('./components/data/CookiePopup'));
const ForgotPassword = lazy(() => import('./pages/small/ForgotPassword'));
const PrivacyPolicy = lazy(() => import('./components/data/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./components/data/TermsAndConditions'));


function App() {
  console.log('helo from app.js');
  const location = useLocation();
  const [showCookiePopup, setShowCookiePopup] = useState(false);
  const [user, setUser] = useState({ id: '', name: '', email: '', profileImg: '' });
  const useHeader = ['', 'cart', 'search', 'services', 'custom', 'profile', 'tools', 'buy-and-sell', 'seller', 'feedback', 'live-support', 'forgot-password', 'password-change', 'seller/forgot-password'];
  // const useHeader = ['', 'cart', 'search', 'services', 'custom', 'profile', 'tools', 'buy-and-sell', 'seller', 'feedback', 'live-support', 'forgot-password', 'password-change', 'seller/forgot-password', 'terms-and-conditions', 'privacy-policy'];
  const normalizedPath = location.pathname.replace(/^\/|\/$/g, '');
  let showHeader = useHeader.some(path => normalizedPath === path && normalizedPath.match('panel') == null) || /product/.test(location.pathname) || location.pathname.slice(1).split('/')[0] === 's';

  if (location.pathname === '/seller/panel/products' || location.pathname === '/seller/panel/products/') {
    showHeader = false;
  } else if (location.pathname.slice(1).split('/').length > 2 && location.pathname.slice(1).split('/')[0] === 'seller') showHeader = false;

  const userDataUpdate = (data) => setUser(data);

  useEffect(() => {
    const dt = JSON.parse(localStorage.getItem('ud'));
    const cookieConsent = localStorage.getItem("cookieConsent");
    
    const loadingHandle = () => {
      if (location.pathname.split('/').includes('product') || location.pathname === '/' || location.pathname === '') return;
      const mainLoaderElm = document.querySelector('.loader-outer.main');
      if (mainLoaderElm) {
        mainLoaderElm.classList.remove('main');
        mainLoaderElm.classList.add('hide');
      }
    }
    loadingHandle();
    
    if (!cookieConsent) setShowCookiePopup(true);
    if (dt) setUser(dt);
    
    let timeoutId;
    timeoutId = setTimeout(() => showLoader(false), 10000);
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [location.pathname]);

  return (
    // <WebSocketProvider userId={user.id} name={user.name}>
    <>
      <div className='notification-main'>
        <div className="notif-icon-container">
          <Suspense fallback={<div>Loading icon...</div>}>
            <span className='savedIcon show'>
              <SavedIcon width={25} height={25} />
            </span>
            <span className='errorIcon dn'>
              <ErrorIcon width={25} height={25} />
            </span>
          </Suspense>
        </div>
        <div className="notif-content-wrapper">
          <p className='title'></p><p className='message'></p>
        </div>
        <div className="notif-action-btns fdc"></div>
      </div>
      {
        showCookiePopup && (
          <Suspense fallback={<></>}>
            <CookiePopup />
          </Suspense>
        )
      }
      <Suspense fallback={<></>}>
        {showHeader && <Header data={user} />}
        <Routes>
          {/* =================== Main Routes =================== */}
          <Route path='/' element={<Home />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/product/:id' element={<Product />} />
          <Route path='/search' element={<Search />} />
          <Route path='/services' element={<Services />} />
          <Route path='/account' element={<User update={userDataUpdate} />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/password-change' element={<ForgotPassword />} />
          <Route path='/custom' element={<CustomOrder />} />
          <Route path='/profile' element={<Profile data={user} update={userDataUpdate} />} />
          <Route path='/tools' element={<Tools />} />
          <Route path='/buy-and-sell' element={<BuyAndSell />} />

          {/* =================== Seller Routes =================== */}
          <Route path='/seller' element={<Seller />} />
          <Route path='/s/:id' element={<SellerPreview />} />
          <Route path='/seller/forgot-password' element={<ForgotPassword />} />
          <Route path="/seller/panel" element={<Dashboard />}>
            <Route index element={<Overview />} />
            <Route path="products" element={<Products />} />
            <Route path="products/edit/:id" element={<AddProductAdmin />} />
            <Route path="orders" element={<Orders />} />
            <Route path="reviews" element={<Reviews />} />
          </Route>

          {/* =================== Admin Routes =================== */}
          <Route path='/admin' element={<Admin />} />
          <Route path='/admin/login' element={<AdminLogin />} />

          {/* =================== Small Page Routes =================== */}
          <Route path='/feedback' element={<Feedback />} />
          <Route path='/live-support' element={<ChatWithUs />} />
          <Route path='/live-support/chat' element={<ChatComponent />} />
          <Route path='/learn-more' element={<LearnMore />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

          {/* =================== error 404 page =================== */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
    // </WebSocketProvider>
  );
}

export default App;