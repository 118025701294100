// src/serviceWorkerRegistration.js

// If you want to enable the service worker, use this to register it.
const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
        /^127(\.[0-9]{1,3}){3}$/
    )
);

export function register() {
    window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        if (isLocalhost) {
            checkValidServiceWorker(swUrl);
        } else {
            registerValidSW(swUrl);
        }
    });
}

function registerValidSW(swUrl) {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
            .register(swUrl)
            .then((registration) => {
                console.log('Service Worker registration successful with scope: ', registration.scope);
            })
            .catch((error) => {
                console.error('Service Worker registration failed: ', error);
            });
    }
}

function checkValidServiceWorker(swUrl) {
    fetch(swUrl)
        .then((response) => {
            if (
                response.status === 404 ||
                response.headers.get('content-type').indexOf('javascript') === -1
            ) {
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister();
                });
            } else {
                registerValidSW(swUrl);
            }
        })
        .catch(() => {
            console.log(
                'No internet connection found. App is running in offline mode.'
            );
        });
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
}